<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <div class="row">
              <div class="col my-auto">
                <h5 class="mb-0">Health Report Templates</h5>
              </div>
              <div class="col-auto my-auto ms-auto">
                <router-link
                  to="/health-reports/create"
                  class="btn btn btn-primary btn-sm"
                  >Create Template</router-link
                >
              </div>
            </div>
          </div>
          <div class="card-body" style="height: 75vh; overflow: scroll">
            <table
              class="table table-sm table-hover"
              v-if="templates.length > 0"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th scope="col">Created</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="t in templates" :key="t.id" class="cursor-pointer">
                  <th scope="row" @click="editTemplate(t.id)">#{{ t.id }}</th>
                  <td v-if="editItem !== t" @click="editTemplate(t.id)">
                    {{ t.title }}
                  </td>
                  <td v-else><input type="text" v-model="t.title" /></td>
                  <td @click="editTemplate(t.id)">
                    {{ t.created_at | formatDate }}
                  </td>
                  <td>
                    <button
                      class="btn btn-xs btn-light"
                      @click="editTitle(t)"
                      v-if="editItem !== t"
                    >
                      Rename
                    </button>
                    <button
                      class="btn btn-xs btn-light"
                      @click="editTitle(t)"
                      v-else
                    >
                      Save
                    </button>
                    <button
                      class="btn btn-xs btn-light"
                      @click="CopyTemplate(t)"
                    >
                      Copy
                    </button>
                  </td>
                  <td>
                    <i
                      class="fa fa-trash cursor-pointer"
                      @click.stop="deleteTemplate(t.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>

            <!--  -->

            <div class="row my-5 text-center pt-5" v-if="templates.length == 0">
              <div class="col">
                <i class="far fa-file-alt fa-6x text-primary mb-5"></i>
                <h5>No Heatlh Report Templates have been created yet</h5>
              </div>
            </div>

            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      templates: [],
      editItem: null,
    };
  },
  methods: {
    CopyTemplate(item) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/documents/copy", {
          target: item.id,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchTemplates();
        });
    },
    editTitle(item) {
      if (this.editItem == item) {
        this.$axios
          .put(process.env.VUE_APP_API_URL + "/documents/" + this.editItem.id, {
            title: item.title,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.editItem = null;
            this.fetchTemplates();
          });
      } else {
        this.editItem = item;
      }
    },
    fetchTemplates() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/documents/fetch")
        .then(({ data }) => {
          this.templates = data;
        });
    },
    editTemplate(id) {
      this.$router.push("/health-reports/" + id);
    },
    deleteTemplate(id) {
      var confirmed = confirm(
        "Are you sure you wish to delete this template, this action cannot be undone?"
      );
      if (confirmed) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/documents/" + id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchTemplates();
          });
      }
    },
    clearStore() {
      this.templates = [];
      this.editItem = null;

      this.$stash.loadedTemplate = null;
      this.$stash.templateItems = [];
      this.$stash.currentSelectedRow = null;
      this.$stash.currentSelectedCol = null;
      this.$stash.currentSelectedColKey = null;
      this.$stash.currentSelectedItem = null;
    },
  },
  beforeDestroy() {
    this.clearStore();
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("LL");
      }
      return "-";
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
  },
  mounted() {
    this.fetchTemplates();
  },
};
</script>

<style>
</style>
